
import Vue from 'vue'
import * as dictionaryApi from '@/api/dictionary'
import Component from 'vue-class-component'

@Component({})
export default class DictionaryInsert extends Vue {
  public saveModel: any = {
    categoryName: '',
    key: '',
    value: ''
  }
  public isUpdate: Boolean = false

  mounted() {
    if (!sessionStorage.getItem('dictionaryAdd')) {
      sessionStorage.setItem('dictionaryAdd', JSON.stringify(this.$route.params))
    }
    let configAddFile: any = sessionStorage.getItem('dictionaryAdd')
    const params = configAddFile ? JSON.parse(configAddFile as any) : this.$route.params
    if (params) {
      this.isUpdate = params.isUpdate == '1'
      this.saveModel = params.saveModel
    }
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    // 当离开组件时，是否允许离开
    sessionStorage.removeItem('dictionaryAdd')
    next()
  }
  goBack() {
    this.$router.push({ name: 'DictionaryList' })
  }

  confirm() {
    let result
    if (this.isUpdate) {
      result = dictionaryApi.updateDictionary(this.saveModel)
    } else {
      result = dictionaryApi.insertDictionary(this.saveModel)
    }
    result
      .then((data) => {
        let response = data.data
        if (response.code != 1000) {
          this.$message({
            type: 'error',
            message: response.message
          })
        } else {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.goBack()
        }
      })
      .catch((err) => {
        this.$message({
          type: 'error',
          message: err
        })
      })
  }
}
