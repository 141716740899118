import request, { request as http } from '@/utils/request'
import { AxiosResponseForZCT } from '@/utils/types'

export function pageDictionary(data: any) {
    return http<AxiosResponseForZCT>({
        url: '/dictionary/page',
        method: 'POST',
        data: data
    });
}

export function getDictionaryByName(categoryName: string) {
    return request({
        url: '/dictionary/getByCategoryName',
        method: 'POST',
        data: {
            categoryName
        }
    });
}

export function insertDictionary(data: any) {
    return http<AxiosResponseForZCT>({
        url: "/dictionary/insert",
        method: 'POST',
        data: data
    });
}

export function deleteDictionary(data: any) {
    return http<AxiosResponseForZCT>({
        url: "/dictionary/delete",
        method: 'POST',
        data: data
    });
}

export function updateDictionary(data: any) {
    return http<AxiosResponseForZCT>({
        url: "/dictionary/update",
        method: 'POST',
        data: data
    });
}