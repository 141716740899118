var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "main",
    { staticClass: "dictionary-insert" },
    [
      _c(
        "el-form",
        {
          ref: "saveModel",
          attrs: { model: _vm.saveModel, "label-width": "150px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分类名称：", prop: "categoryName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入分类名称" },
                model: {
                  value: _vm.saveModel.categoryName,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "categoryName", $$v)
                  },
                  expression: "saveModel.categoryName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Key：", prop: "key" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入Key" },
                model: {
                  value: _vm.saveModel.key,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "key", $$v)
                  },
                  expression: "saveModel.key"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Value：", prop: "value" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入Value" },
                model: {
                  value: _vm.saveModel.value,
                  callback: function($$v) {
                    _vm.$set(_vm.saveModel, "value", $$v)
                  },
                  expression: "saveModel.value"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "t_c", staticStyle: { "text-align": "center" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.confirm()
                }
              }
            },
            [_vm._v(_vm._s(_vm.isUpdate ? "修改" : "新增"))]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }